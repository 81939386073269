
























































































import { Component, Vue, Prop } from "vue-property-decorator";
import { ImageData } from '../../functions/lib/ImageData'
import ReportCard from "./report-card/ReportCard.vue";

function getNestedValue(
  obj: any,
  path: (string | number)[],
  fallback?: any
): any {
  const last = path.length - 1;

  if (last < 0) return obj === undefined ? fallback : obj;

  for (let i = 0; i < last; i++) {
    if (obj == null) {
      return fallback;
    }
    obj = obj[path[i]];
  }

  if (obj == null) return fallback;

  return obj[path[last]] === undefined ? fallback : obj[path[last]];
}

function getObjectValueByPath(obj: any, path: string, fallback?: any): any {
  // credit: http://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-with-string-key#comment55278413_6491621
  if (obj == null || !path || typeof path !== "string") return fallback;
  if (obj[path] !== undefined) return obj[path];
  path = path.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  path = path.replace(/^\./, ""); // strip a leading dot
  return getNestedValue(obj, path.split("."), fallback);
}

@Component({
  components: {
    ReportCard
  }
})
export default class ReportCardGrid extends Vue {
  @Prop(Boolean)
  public expandable!: boolean;

  @Prop(Boolean)
  public showAddress!: boolean;

  @Prop(Array)
  public items!: ImageData[];

  private search: string = "";

  groupItems(
    items: ImageData[],
    groupBy: string[],
    groupDesc: boolean[]
  ): Record<string, ImageData[]> {
    const key = groupBy[0];

    if (key === "date") {
      const groupedByMonth: Record<string, ImageData[]> = {};

      if (items.filter(item => !item.send).length > 0) {
        groupedByMonth["Neu / Ungesendet"] = items.filter(item => !item.send);
      }

      for (let item of items
        .filter(item => item.send)
        .sort((a, b) => b.date! - a.date!)) {
        const monthId = new Date(item.date! * 1000).toLocaleString(undefined, {
          month: "long",
          year: "numeric"
        });

        if (!groupedByMonth[monthId]) {
          groupedByMonth[monthId] = [];
        }

        groupedByMonth[monthId].push(item);
      }

      if (!groupDesc) {
        return Object.fromEntries(Object.entries(groupedByMonth).reverse());
      }

      return groupedByMonth;
    } else {
      const key = groupBy[0];
      return items.reduce((acc, item) => {
        const val = getObjectValueByPath(item, key);
        (acc[val] = acc[val] || []).push(item);
        return acc;
      }, {} as Record<string, ImageData[]>);
    }
  }

  publishItemEvent(event: string, item: ImageData, data: unknown) {
    this.$emit(event, { item: item, newValue: data });
  }
}
