






















import { Component, Vue } from 'vue-property-decorator'
import firebase from 'firebase/app'
import 'firebase/auth'
import { uploadImage } from '../uploadImage'

@Component
export default class Upload extends Vue {
  private files: File[] = []
  private progress: number = 0

  async handleFileSelect (e: Event) {
    if (this.files === null) return

    if (!firebase.auth().currentUser) {
      this.$router.push('/login')
      return
    }

    this.progress = 0

    for (let i = 0; i < this.files.length; i++) {
      uploadImage(this.files[i]).then(() => {
        this.progress++;
      })
    }
  }
}
