


























import { Component, Vue } from 'vue-property-decorator'
import  firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import { ImageData } from '../../functions/lib/ImageData'
import '../vuefire'
import ReportCardGrid from '@/components/ReportCardGrid.vue'
import Upload from '@/components/Upload.vue'

type FirebaseImageData = ImageData & {'.key': string}

@Component({
  firebase () {
    const db =  firebase.database()
    const user =  firebase.auth().currentUser

    return {
      items: db.ref('users').child(user ? user.uid : 'no-user').child('images')
    }
  },
  components: {
    ReportCardGrid,
    Upload
  }
})
export default class Send extends Vue {
  private getFirebaseItemRef (item: FirebaseImageData) {
    return this.$firebaseRefs.items.child(item['.key'])
  }

  private setFirebaseItemProp (prop: keyof ImageData, item: FirebaseImageData, newValue: unknown) {
    this.getFirebaseItemRef(item).child(prop).set(newValue)
  }

  deleteItem (item: FirebaseImageData) {
    this.getFirebaseItemRef(item).remove()
  }

  send (item: FirebaseImageData) {
    this.getFirebaseItemRef(item).child('send').set(true)
  }

  mapItemsToKfzCount(items: FirebaseImageData[]): Map<string| undefined, number> {
    return items.reduce((acc, item) => {
      acc.set(item.plate, (acc.get(item.plate) ?? 0) + 1);
      return acc;
    }, new Map())
  }
  
  convertItems(items: FirebaseImageData[]) {
    console.log(items.map(item => item.loc))
    const kfzCounts = this.mapItemsToKfzCount(items);

  console.log(kfzCounts);
    return items.map(item => ({
      ... item,
      images: [item.filePath],
      plateCount: kfzCounts.get(item.plate) ?? 0
    }))
  }  
}
